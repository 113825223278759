import React, { useState } from "react";
import AppProvider from "./AppProvider";
import DisclaimerScreen from "./DisclaimerScreen";
import FormScreen from "./FormScreen";
import ResultScreen from "./ResultScreen";
import "../App.css";

function App() {
  const [disclaimer, setDisclaimer] = useState(true);
  const [dishData, setDishData] = useState(false);
  const [formData, setFormData] = useState({});

  return (
    <div>
      <AppProvider
        disclaimer={disclaimer}
        setDisclaimer={setDisclaimer}
        dishData={dishData}
        setDishData={setDishData}
        formData={formData}
        setFormData={setFormData}>
        {disclaimer ? (
          <DisclaimerScreen />
        ) : (
          <>{dishData ? <ResultScreen /> : <FormScreen />}</>
        )}
      </AppProvider>
    </div>
  );
}

export default App;
