import React, { useContext, useState } from "react";
import AppContext from "../context/AppContext";
import axios from "axios";
import "./FormScreen.css";
import Logo from "./HungryBudgetLogo.png";

function FormScreen() {
  const appContext = useContext(AppContext);
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    appContext.setFormData({ ...appContext.formData, [name]: value });
  };

  const handleInputBudget = (event) => {
    const { name, value } = event.target;

    // Limit input to 3 digits
    const maxDigits = 3;
    const input = value.slice(0, maxDigits);

    appContext.setFormData({ ...appContext.formData, [name]: input });

    if (
      name === "minBudget" &&
      parseInt(input) > parseInt(appContext.formData.maxBudget)
    ) {
      appContext.setFormError(
        "Minimum budget cannot be greater than maximum budget"
      );
    } else if (
      name === "maxBudget" &&
      parseInt(input) < parseInt(appContext.formData.minBudget)
    ) {
      appContext.setFormError(
        "Maximum budget cannot be less than minimum budget"
      );
    } else {
      appContext.setFormError("");
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "e" || event.key === "E") {
      event.preventDefault();
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const { budget, course, city, minBudget, maxBudget } = appContext.formData;
    axios
      .post("https://hungrybudget.com/endpoints/registerUserInput.php", {
        budget,
        course,
        city,
        minBudget,
        maxBudget,
      })
      .then((response) => {
        console.log(response);
        console.log("este es el evento", event);
        appContext.handleFormSubmit(event);
      })
      .catch((error) => {
        console.log("should be printing ", error);
        console.log(appContext.formError, "context error");
        appContext.setFormError("Oopsie! Something went wrong.");
        setShowErrorMessage(true);
      });
  };

  return (
    <div>
      <div className='logo-wrapper'>
        <img className='logo' src={Logo} alt='Hungry Budget Logo' />
      </div>

      <form onSubmit={handleSubmit}>
        {appContext.formError && (
          <div className='error-message'>{appContext.formError}</div>
        )}
        <div className='budget-wrapper'>
          <div className='budget-text'>Budget</div>
          <div className='min-max-together'>
            <div>
              <label htmlFor='minBudget'>Min</label>
              <input
                type='number'
                id='minBudget'
                name='minBudget'
                min='0'
                max='999'
                value={appContext.formData.minBudget}
                onChange={handleInputChange}
                onInput={handleInputBudget}
                onKeyPress={handleKeyPress}
              />
            </div>
            <div>
              <label htmlFor='maxBudget'>Max</label>
              <input
                type='number'
                id='maxBudget'
                name='maxBudget'
                min='0'
                max='999'
                value={appContext.formData.maxBudget}
                onChange={handleInputChange}
                onInput={handleInputBudget}
                onKeyPress={handleKeyPress}
                required
              />
            </div>
          </div>
        </div>
        <div className='locate'>
          <div>
            <label htmlFor='city'>Location:</label>
            <select id='city' name='city' onChange={handleInputChange} required>
              <option value=''></option>
              <option value='provo'>Provo</option>
              <option value='orem'>Orem</option>
            </select>
          </div>
          <div className='only-option'>
            <label htmlFor='local'>Local Only</label>
            <input
              id='local'
              type='checkbox'
              name='local'
              value='1'
              onChange={handleInputChange}
              className='advanced-options-select'></input>
          </div>
        </div>
        <div className='locate'>
          <div className='meal-wrapper'>
            <label htmlFor='course'>Meal:</label>
            <select
              id='course'
              name='course'
              onChange={handleInputChange}
              required
              className='meal-select'>
              <option value=''></option>
              <option value='breakfast'>Breakfast</option>
              <option value='dinner'>Dinner</option>
              <option value='dessert'>Dessert</option>
            </select>
            {showErrorMessage && (
              <div className='error-message'>Please select a meal</div>
            )}
          </div>
          <div className='only-option'>
            <label htmlFor='vegan'>Vegan Only</label>
            <input
              id='vegan'
              type='checkbox'
              name='vegan'
              value='1'
              onChange={handleInputChange}
              className='advanced-options-select'></input>
          </div>
        </div>
        <div className='advanced-options'>
          <span
            className={`advanced-options-toggle ${
              showAdvancedOptions ? "advanced-options-toggle-show" : ""
            }`}
            onClick={() => {
              if (!appContext.formData.course) {
                setShowErrorMessage(true);
              } else {
                setShowErrorMessage(false);
                setShowAdvancedOptions(!showAdvancedOptions);
              }
            }}>
            Advanced{" "}
            {showAdvancedOptions ? (
              <span className='arrow-right arrow-down'></span>
            ) : (
              <span className='arrow-right'></span>
            )}
          </span>
        </div>
        {appContext.formData.course === "breakfast" && (
          <div
            className={`advanced-options-content ${
              showAdvancedOptions ? "advanced-options-content-show" : ""
            }`}>
            <div>
              <label htmlFor='salty'>Salty:</label>
              <select
                id='salty'
                type='checkbox'
                name='salty'
                onChange={handleInputChange}
                className='advanced-options-select'>
                <option value='1'>Yes</option>
                <option value='0'>No</option>
              </select>
            </div>
            <div>
              <label htmlFor='sweet'>Sweet:</label>
              <select
                id='sweet'
                type='checkbox'
                name='sweet'
                onChange={handleInputChange}
                className='advanced-options-select'>
                <option value='1'>Yes</option>
                <option value='0'>No</option>
              </select>
            </div>
            <div>
              <label htmlFor='spicy'>Spicy:</label>
              <select
                id='spicy'
                type='checkbox'
                name='spicy'
                onChange={handleInputChange}
                className='advanced-options-select'>
                <option value='1'>Yes</option>
                <option value='0'>No</option>
              </select>
            </div>
            <div>
              <label htmlFor='egg'>Egg:</label>
              <select
                id='egg'
                type='checkbox'
                name='egg'
                onChange={handleInputChange}
                className='advanced-options-select'>
                <option value='1'>Yes</option>
                <option value='0'>No</option>
              </select>
            </div>
            <div>
              <label htmlFor='fruit'>Fruit:</label>
              <select
                id='fruit'
                type='checkbox'
                name='fruit'
                onChange={handleInputChange}
                className='advanced-options-select'>
                <option value='1'>Yes</option>
                <option value='0'>No</option>
              </select>
            </div>
          </div>
        )}
        {appContext.formData.course === "dinner" && (
          <div
            className={`advanced-options-content ${
              showAdvancedOptions ? "advanced-options-content-show" : ""
            }`}>
            <div>
              <label htmlFor='salty'>Salty:</label>
              <select
                id='salty'
                type='checkbox'
                name='salty'
                onChange={handleInputChange}
                className='advanced-options-select'>
                <option value='1'>Yes</option>
                <option value='0'>No</option>
              </select>
            </div>
            <div>
              <label htmlFor='sweet'>Sweet:</label>
              <select
                id='sweet'
                type='checkbox'
                name='sweet'
                onChange={handleInputChange}
                className='advanced-options-select'>
                <option value='1'>Yes</option>
                <option value='0'>No</option>
              </select>
            </div>
            <div>
              <label htmlFor='spicy'>Spicy:</label>
              <select
                id='spicy'
                type='checkbox'
                name='spicy'
                onChange={handleInputChange}
                className='advanced-options-select'>
                <option value='1'>Yes</option>
                <option value='0'>No</option>
              </select>
            </div>
            <div>
              <label htmlFor='beef'>Beef:</label>
              <select
                id='beef'
                type='checkbox'
                name='beef'
                onChange={handleInputChange}
                className='advanced-options-select'>
                <option value='1'>Yes</option>
                <option value='0'>No</option>
              </select>
            </div>
            <div>
              <label htmlFor='chicken'>Chicken:</label>
              <select
                id='chicken'
                type='checkbox'
                name='chicken'
                onChange={handleInputChange}
                className='advanced-options-select'>
                <option value='1'>Yes</option>
                <option value='0'>No</option>
              </select>
            </div>
            <div>
              <label htmlFor='fish'>Sea Food:</label>
              <select
                id='fish'
                type='checkbox'
                name='fish'
                onChange={handleInputChange}
                className='advanced-options-select'>
                <option value='1'>Yes</option>
                <option value='0'>No</option>
              </select>
            </div>
            <div>
              <label htmlFor='noProtein'>No Protein:</label>
              <select
                id='noProtein'
                type='checkbox'
                name='noProtein'
                onChange={handleInputChange}
                className='advanced-options-select'>
                <option value='1'>Yes</option>
                <option value='0'>No</option>
              </select>
            </div>
          </div>
        )}
        {appContext.formData.course === "dessert" && (
          <div
            className={`advanced-options-content ${
              showAdvancedOptions ? "advanced-options-content-show" : ""
            }`}>
            <div>
              <label htmlFor='salty'>Salty:</label>
              <select
                id='salty'
                type='checkbox'
                name='salty'
                onChange={handleInputChange}
                className='advanced-options-select'>
                <option value=''>No Preference</option>
                <option value='1'>Yes</option>
                <option value='0'>No</option>
              </select>
            </div>
            <div>
              <label htmlFor='sweet'>Sweet:</label>
              <select
                id='sweet'
                type='checkbox'
                name='sweet'
                onChange={handleInputChange}
                className='advanced-options-select'>
                <option value=''>No Preference</option>
                <option value='1'>Yes</option>
                <option value='0'>No</option>
              </select>
            </div>
            <div>
              <label htmlFor='dry'>Dry:</label>
              <select
                id='dry'
                type='checkbox'
                name='dry'
                onChange={handleInputChange}
                className='advanced-options-select'>
                <option value=''>No Preference</option>
                <option value='1'>Yes</option>
                <option value='0'>No</option>
              </select>
            </div>{" "}
            <div>
              <label htmlFor='frozen'>Frozen:</label>
              <select
                id='frozen'
                type='checkbox'
                name='frozen'
                onChange={handleInputChange}
                className='advanced-options-select'>
                <option value=''>No Preference</option>
                <option value='1'>Yes</option>
                <option value='0'>No</option>
              </select>
            </div>
            <div>
              <label htmlFor='liquid'>Liquid:</label>
              <select
                id='liquid'
                type='checkbox'
                name='liquid'
                onChange={handleInputChange}
                className='advanced-options-select'>
                <option value=''>No Preference</option>
                <option value='1'>Yes</option>
                <option value='0'>No</option>
              </select>
            </div>
          </div>
        )}
        <div>
          <button type='submit' className='submit-button'>
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}

export default FormScreen;
