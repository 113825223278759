import React, { useContext } from "react";
import AppContext from "../context/AppContext";
import "./DisclaimerScreen.css";

function DisclaimerScreen() {
  const appContext = useContext(AppContext);

  const handleClick = () => {
    appContext.setDisclaimer((prevDisclaimer) => !prevDisclaimer);
  };

  return (
    <div className='wrapper'>
      {appContext.disclaimer && (
        <div className='disclaimer'>
          <p className='disclaimer-text'>
            The menu items that will be mentioned are subject to change by the
            restaurants and we cannot guarantee the accuracy of the prices
            mentioned at the time of your visit to the restaurant.
          </p>
          <button className='disclaimer-button' onClick={handleClick}>
            Agree
          </button>
        </div>
      )}
    </div>
  );
}

export default DisclaimerScreen;
