import React, { useContext, useEffect, useState } from "react";
import AppContext from "../context/AppContext";
import axios from "axios";
import "./ResultScreen.css";

function ResultScreen() {
  const [extraInfo, setExtraInfo] = useState([]);
  const appContext = useContext(AppContext);
  const restaurantId = appContext.dishData.restaurantId;

  useEffect(() => {
    if (restaurantId === 0) {
      //do nothing
    } else {
      axios
        .post(
          "https://hungrybudget.com/endpoints/getMenu.php",
          `restaurantId=${restaurantId}`
        )
        .then((response) => {
          const menu = response.data.data[0];
          setExtraInfo(menu);
        })
        .catch((error) => console.log(error));
    }
  }, [restaurantId]);

  const newSuggestion = () => {
    axios
      .post(
        "https://hungrybudget.com/endpoints/dishes.php",
        appContext.formData
      )
      .then((response) => {
        appContext.setDishData(response.data[0]);

        axios
          .post("https://hungrybudget.com/endpoints/incrementFoodCount.php", {
            foodId: response.data[0].id,
          })
          .then(() => {
            //nothing is needed here
          });

        const currentRestaurantId = response.data[0].restaurantId;
        axios
          .post(
            "https://hungrybudget.com/endpoints/getMenu.php",
            `restaurantId=${currentRestaurantId}`
          )
          .then((response) => {
            const menu = response.data.data[0];
            setExtraInfo(menu);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const changeFilters = () => {
    appContext.setFormData({});
    appContext.setDishData(null);
    appContext.setFormError("");
  };

  const handleAddressClick = () => {
    const address = appContext.dishData.restaurantAddress;
    if (/(android)/i.test(navigator.userAgent)) {
      window.location.href = `geo:0,0?q=${address}`;
    } else {
      window.open(`http://maps.apple.com/?q=${address}`, "_blank");
    }

    axios
      .post("https://hungrybudget.com/endpoints/addressLinkClick.php", {
        foodId: appContext.dishData.id,
      })
      .then((response) => {})
      .catch((error) => {
        console.log(error);
      });
  };

  const restaurantMenuClick = (e) => {
    e.preventDefault();
    window.open(extraInfo.restaurantMenu, "_blank");
    axios.post("https://hungrybudget.com/endpoints/menuLinkClicks.php", {
      foodId: appContext.dishData.id,
    });
  };
  return (
    <div>
      {appContext.dishData ? (
        <div className='wrapper'>
          <div className='image-side'>
            <img
              className='image'
              src={
                "https://www.hungrybudget.com/images/" +
                appContext.dishData.dishPicture
              }
              alt={appContext.dishData.dishName}
            />
          </div>
          <div className='info-side'>
            <div className='dish-title'>
              <h2>{appContext.dishData.dishName}</h2>
            </div>
            <p className='section-title'>Description</p>
            <p className='content'>{appContext.dishData.dishDescription}</p>
            <p className='section-title'>Price</p>
            <p className='content'>{appContext.dishData.dishPrice}</p>
            <p className='section-title'>Restaurant</p>
            <p className='content'>{appContext.dishData.restaurantName}</p>
            <p className='section-title'>Address</p>
            <p className='content address' onClick={handleAddressClick}>
              {appContext.dishData.restaurantAddress}
            </p>
            {extraInfo && (
              <div className='pro-restaurants'>
                <p className='section-title'>Pro Restaurant Options</p>
                <a
                  className='rest-of-menu'
                  href={extraInfo.restaurantMenu}
                  onClick={restaurantMenuClick}>
                  Restaurant Menu
                </a>
              </div>
            )}

            <button onClick={newSuggestion}>Generate new suggestion</button>
            <button onClick={changeFilters}>Change filters</button>
            <br />
          </div>
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
}

export default ResultScreen;
